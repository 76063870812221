import React from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import Main from './pages/Main'
import MainDelivery from './pages/MainDelivery'
import Poll from './pages/Poll'
import Pickup from './pages/Pickup'

export const AuthContext = React.createContext()

const App = () => {

  return (

    <div className="App">
        <Routes>
          <Route exact path='/mariscoselrey/obregon' element={ <Main sucursal={1} /> }/>
          <Route exact path='/mariscoselrey/guaymas' element={ <Main sucursal={2}  /> }/>
          <Route exact path='/mariscoselrey/hermosillo' element={ <Main sucursal={3}  /> }/>
          <Route path='/mariscoselrey/domicilio/:sucursalId' element={ <MainDelivery sucursal={4} /> }/>
          <Route exact path='/mariscoselrey/sancarlos' element={ <Main sucursal={5} /> }/>
          <Route exact path='/mariscoselrey/pruebas' element={ <Main sucursal={6} /> }/>
          <Route exact path='/mariscoselrey/salida-obregon' element={ <Poll sucursal={1} /> } />
          <Route exact path='/mariscoselrey/thebigkraken' element={ <Main sucursal={7} /> } />
          <Route exact path='/mariscoselrey/elreyexpress' element={ <Pickup sucursal={8}  /> } />
          <Route exact path='/mariscoselrey/morelos' element={ <Main sucursal={9} /> } />
          
         
        </Routes>
    </div>

  )
  
}

export default App